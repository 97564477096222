import React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import "./footer.css";

const Footer = () => {


  var ruta = window.location.origin;

  if(ruta ==="http://10.97.85.129")
    ruta=ruta+"/gestor_eventos/"

  return (
    <footer style={{ backgroundColor: "#212121", color: "white" }}>
      <div className="container-fluid fw-bold p-md-3">
        <div className="row m-0">
          <div className="col-md-8 col-12 mb-8 responsive-text">
            <Link href="https://www.tec.mx" target="_blank">
              <img
                src="https://admision.tec.mx/footer-tec-mx/img/HorizontalPrimarioBlanco.svg"
                className="logo"
                alt="Logo TEC"
              />
            </Link>
          </div>

          <div className="col-md-4 col-12 mb-4 text-right text-center responsive-text">
            <Typography variant="h6" className="text-white mb-3">
              Síguenos 
            </Typography>
            <ul className="inline-menu mb-4">
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.facebook.com/tecdemonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <FacebookIcon className="link-icon facebook" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://twitter.com/tecdemonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <XIcon className="link-icon twitter" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/company/itesm"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <LinkedInIcon className="link-icon linkedin" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.youtube.com/TECdeMonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <YouTubeIcon className="link-icon youtube" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.instagram.com/tecdemonterrey/"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <InstagramIcon className="link-icon instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>


        <Grid item xs={12} className="text-center">
            <Typography
              variant="body1"
              className="mb-2"
              sx={{ fontSize: "12px", fontWeight: "700" }}
            >
              AV. EUGENIO GARZA SADA 2501 SUR COL. TECNOLÓGICO C.P. 64700 |
              MONTERREY, NUEVO LEÓN, MÉXICO | TEL. +52 (81) 8358-2000 D.R.©
              INSTITUTO TECNOLÓGICO Y DE ESTUDIOS SUPERIORES DE MONTERREY,
              MÉXICO. {new Date().getFullYear()}
            </Typography>

          </Grid>
      </div>
      <hr></hr>

      <Container maxWidth="xl" className="p-2 pb-4 px-5 pt-md-4 pb-md-4">
        <Grid container>
          <Grid item xs={12} className="text-center">
          <Typography
              variant="caption"
              style={{ fontSize: "8px", fontWeight: 400 }}
              className="mb-2"
            >
              *DEC-520912 PROGRAMAS EN MODALIDAD ESCOLARIZADA.
            </Typography>
            
            <Typography
              variant="body1"
              className="text-uppercase"
              sx={{ fontSize: "12px", fontWeight: "700" }}
            >
              <Link
                href="https://tec.mx/es/aviso-legal"
                target="_blank"
                className="text-white mr-1"
                sx={{ textDecoration: "none" }}
              >
                Aviso legal 
              </Link>{"  "}
              | 
              <Link
                href="https://tec.mx/es/politicas-de-privacidad-del-tecnologico-de-monterrey"
                target="_blank"
                className="text-white ml-1 mr-1"
                sx={{ textDecoration: "none" }}
              >
                Políticas de privacidad 
              </Link>{"  "}
              | 
              <Link
                href="https://tec.mx/es/avisos-de-privacidad"
                target="_blank"
                className="text-white ml-1 mr-1"
                sx={{ textDecoration: "none" }}
              >
                Aviso de privacidad 
              </Link>{"  "}
              | 
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
