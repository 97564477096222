import React, { useState, useEffect } from "react";
import { apiUrlAced, apiUrlGestor, urlRoot } from "../../global";
import Banner from "../../components/Banner";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LogoTecNgo from "../../img/logo-tec-negro.svg";
import { useTheme } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import ArrowIcon from "../../components/ArrowIcon";
import CarouselComponent from "../../components/Carousel";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { EventoDestacado } from "../../components/EventoDestacado";
import { Navbar } from "../../components/Navbar";
import axios from "axios";
import Footer from "../../components/Footer";
import "./loading.css";
import { obtenerCampus } from "../../helper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  backgroundColor: "background.paper",
  border: "2px #F5F8F2",
  boxShadow: 24,
  p: 4,
  borderRadius: "11px 11px 11px 11px",
};

const Inicio = () => {
  const handleCampusChangeInParent = (selectedCampus, nivel) => {
    setIsLoadingEventos(true);
    let campusSinPrefijo = selectedCampus;
    let campusName = "";
    if (selectedCampus.includes("MTY-")) {
      campusSinPrefijo = selectedCampus.replace("MTY-", "");
      campusName = campusSinPrefijo;
      setnombreCampus(campusSinPrefijo);
    } else {
      campusName = selectedCampus;
      setnombreCampus(selectedCampus);
    }
    fetchCampuses(nivel);
    setEventos([]);
    axios
      .post(apiUrlAced + "campuses/search", {
        grade: nivel,
        name: campusName,
      })
      .then(function (response) {
        const campusData = response.data.data;
        console.log("campusData");
        console.log(campusData);

        const cambiosDeNombres = {
          Cumbres: "MTY-Cumbres",
          "Santa Catarina": "MTY-Santa Catarina",
          "Eugenio Garza Sada": "MTY-Eugenio Garza Sada",
          "Eugenio Garza Lagüera": "MTY-Eugenio Garza Lagüera",
          "Valle Alto": "MTY-Valle Alto",
        };
        campusData.forEach((campus) => {
          if (cambiosDeNombres.hasOwnProperty(campus.name)) {
            campus.name = cambiosDeNombres[campus.name];
          }
        });
        fetchEventos(campusData[0]["sf_key"], nivel).then(() => {
          setIsLoadingEventos(false);
        });
        handleCampusChange(selectedCampus);
        localStorage.setItem("campusStorageName", selectedCampus);
        let campusSinPrefijo = selectedCampus;

        if (selectedCampus.includes("MTY-")) {
          campusSinPrefijo = selectedCampus.replace("MTY-", "");

          setnombreCampus(campusSinPrefijo);
        } else {
          setnombreCampus(selectedCampus);
        }
        localStorage.setItem("campusStorage", campusData[0]["sf_key"]);
        localStorage.setItem("nivelStorage", nivel);
        localStorage.setItem("campusAlias", campusData[0]["alias"]);
        setSelectedGrade(nivel);
        fetchImgName();
      })
      .catch(function (error) {
        localStorage.setItem("campusStorageName", "");
        localStorage.setItem("campusStorage", "");
        // localStorage.setItem("nivelStorage", "");
        setSelectedGrade("");
        // handleCampusChange("");
        setSelectedOption(localStorage.getItem("nivelStorage"));
        if (localStorage.getItem("nivelStorage") !== null) {
          handleChangeGrade(localStorage.getItem("nivelStorage"));
        }

        // setSelectedOption("");
        setSelectedCampus("");
        setOpen(true);
        console.log(error);
      });
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCampusName, setSelectedCampusName] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedCampus, setSelectedCampus] = useState("");
  const [eventos, setEventos] = useState([]);
  const [campus, setCampus] = useState([]);
  const [imgName, setImgName] = useState("");
  const [carruselEventos, setCarruselEventos] = useState([]);
  const [nombreCampus, setnombreCampus] = useState("");

  //CAPTURAMOS EN LOCAL STORAGE EL CAMPUS
  const handleCampusChange = (selectedCampusName) => {
    setSelectedCampusName(selectedCampusName);
    localStorage.setItem("campusStorageName", selectedCampusName);
  };

  //Verificar si ya hay algo en local storage
  var validaModal = false;
  if (
    localStorage.getItem("nivelStorage") === null ||
    localStorage.getItem("campusStorage") === null
  ) {
    validaModal = true;
  }

  //MODAL
  const [open, setOpen] = React.useState(validaModal);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Aplica el ancho del 100% si es un dispositivo móvil
  if (isMobile) {
    style.width = "100%";
  } else {
    style.width = "50%"; // Define el ancho predeterminado para otras pantallas
  }

  async function fetchCampuses(selectedGrade) {
    try {
      if (selectedGrade) {
        const campuses = await obtenerCampus(selectedGrade);
        setCampus(campuses);
        localStorage.setItem("nivelStorage", selectedGrade);
      }
    } catch (error) {
      // Manejo de errores
      console.error("Error fetching campuses:", error);
    }
  }

  const fetchEventos = async (selectedCampus, selectedGrade) => {
    setIsLoadingEventos(true);
    setCarruselEventos([]);

    try {
      if (!selectedCampus) {
        handleCampusChangeInParent(selectedCampus, selectedGrade);
        return;
      }

      localStorage.setItem("campusStorage", selectedCampus);

      let nivelSel2 = selectedGrade || "";
      let campusSel = selectedCampus;
      let buscamos = "";

      switch (nivelSel2) {
        case "profesional":
          buscamos = "&n=05";
          break;
        case "prepatec":
          buscamos = "&n=03";
          break;
        default:
          buscamos = ""; // Manejar el caso por defecto
          break;
      }

      const [eventosResponse, carruselResponse] = await Promise.all([
        fetch(
          apiUrlGestor +
            `obtenerEventosHome.php?c=${campusSel}${buscamos}&cuantos=12`
        ),
        fetch(
          apiUrlGestor + `obtenerEventosCarrusel.php?c=${campusSel}${buscamos}`
        ),
      ]);

      const [eventosData, carruselData] = await Promise.all([
        eventosResponse.json(),
        carruselResponse.json(),
      ]);

      let eventos = [];
      eventos = eventos.concat(eventosData.events);
      if (eventos.length === 0) {
        setEventos("error");
      } else {
        setEventos(eventos);
      }

      if (
        carruselData.events &&
        carruselData.events[1] &&
        carruselData.events[1].type !== undefined
      ) {
        setCarruselEventos([]);
      } else {
        setCarruselEventos(carruselData.events);
      }
    } catch (error) {
      console.error("Error en la función fetchEventos:", error);
      setEventos("error");
      setCarruselEventos([]);
    } finally {
      setIsLoadingEventos(false);
    }
  };

  const fetchImgName = () => {
    fetch(apiUrlGestor + "obtenerImg.php")
      .then((response) => response.json())
      .then((data) => {
        setImgName(data["data"][0]["imagen"]);
      })
      .catch((error) => {
        // Manejo de errores
        console.log("Error al obtener los banners", error);
      });
  };

  const handleChangeGrade = (event) => {
    let selectedGrade; // Cambiamos a let en lugar de const

    if (typeof event === "string") {
      selectedGrade = event; // Obtener el valor seleccionado del Select
    } else {
      selectedGrade = event.target.value; // Obtener el valor seleccionado del Select
    }

    setSelectedOption(selectedGrade);
    setSelectedGrade(selectedGrade);
    fetchCampuses(selectedGrade);
  };

  const handleChangeCampus = (event) => {
    const selectedCampusValue = event.target.value;
    const selectedCampusName = campus.find(
      (campusGet) => campusGet.sf_key === selectedCampusValue
    )?.name;
    setSelectedCampus(selectedCampusValue);
    setSelectedCampusName(selectedCampusName);
    localStorage.setItem("campusStorageName", selectedCampusName);

    let campusSinPrefijo = selectedCampusName;
    if (selectedCampusName.includes("MTY-")) {
      campusSinPrefijo = selectedCampusName.replace("MTY-", "");
      setnombreCampus(campusSinPrefijo);
    } else {
      setnombreCampus(selectedCampusName);
    }

    fetchCampuses(selectedGrade);
    fetchEventos(selectedCampusValue, selectedGrade).then(() => {
      setIsLoadingEventos(false);
    });
    fetchImgName();
    handleClose();
    handleCampusChange(selectedCampusName);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEventos, setIsLoadingEventos] = useState(true);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const __nivel = queryParameters.get("nivel");
    const __campus = queryParameters.get("campus");

    const campusFromLocalStorage = localStorage.getItem("campusStorageName");
    if (__nivel && __campus) {
      (async () => {
        let __CampusData = await obtenerCampus(__nivel, __campus);
        console.log(__CampusData);
        localStorage.setItem("campusStorageName", __CampusData[0]["name"]);
        localStorage.setItem("campusStorage", __CampusData[0]["sf_key"]);
        localStorage.setItem("nivelStorage", __nivel);
        setSelectedOption(__nivel);
        setSelectedGrade(__nivel);
        setSelectedCampus(__CampusData[0]["sf_key"]);
        setSelectedCampusName(__CampusData[0]["name"]);
        setnombreCampus(__CampusData[0]["name"]);
        fetchEventos(
          localStorage.getItem("campusStorage"),
          localStorage.getItem("nivelStorage")
        );
      })();
    } else {
      if (campusFromLocalStorage) {
        setSelectedOption(localStorage.getItem("nivelStorage"));
        setSelectedGrade(localStorage.getItem("nivelStorage"));

        setSelectedCampus(localStorage.getItem("campusStorage"));
        setSelectedCampusName(campusFromLocalStorage);
        let campusSinPrefijo = campusFromLocalStorage;
        if (campusFromLocalStorage.includes("MTY-")) {
          campusSinPrefijo = campusFromLocalStorage.replace("MTY-", "");
          setnombreCampus(campusSinPrefijo);
        } else {
          setnombreCampus(campusFromLocalStorage);
        }
        fetchEventos(
          localStorage.getItem("campusStorage"),
          localStorage.getItem("nivelStorage")
        )
          .then(() => {
            setIsLoadingEventos(false);
          })
          .catch((error) => {
            console.error("Error en fetchEventos:", error);
            // Manejo de errores
          });
      } else {
        handleCampusChangeInParent(selectedCampus, selectedGrade);
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1200); // 1500 milisegundos = 1.5 segundos
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      ) : (
        <div>
          <div>
            <Modal
              isOpen={handleOpen}
              onRequestClose={handleClose}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              TransitionComponent={Fade}
              transitionDuration={500}
              BackdropProps={{
                style: {
                  backdropFilter: "blur(8px)",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
              PaperProps={{
                style: {
                  border: "none",
                  boxShadow: "none",
                },
              }}
            >
              <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={LogoTecNgo}
                    alt="Logo del Tecnológico de Monterrey"
                    style={{
                      width: "30%",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  />
                </Box>

                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "23px",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  ¡Te damos la bienvenida al Calendario de Admisiones y Becas!
                </Typography>

                <Typography>
                  <hr></hr>
                </Typography>

                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    color: "#676767",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  Indícanos el nivel y campus de tu interés, para conocer los
                  eventos que tenemos preparados para ti.
                </Typography>
                <br></br>

                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ "margin-top": "-5px", top: "14%", left: "0.70%" }}
                    >
                      Elige tu nivel
                    </InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        height: 40,
                        marginRight: 15,
                        color: "#676767",
                        "& .MuiSvgIcon-root": {
                          color: "#19d3c5",
                          "margin-right": "3px",
                        },
                      }}
                      IconComponent={ArrowIcon}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Elige tu nivel"
                      value={selectedOption}
                      onChange={handleChangeGrade}
                    >
                      <MenuItem value={"profesional"}>Profesional</MenuItem>
                      <MenuItem value={"prepatec"}>PrepaTec</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      htmlFor="SelCamp"
                      sx={{
                        "&.Mui-focused": { color: "#676767" },
                        "margin-top": "-5px",
                        top: "14%",
                        left: "0.70%",
                      }}
                      IconComponent={ArrowIcon}
                      // Agrega estilos personalizados según sea necesario
                    >
                      Elige tu campus
                    </InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        height: 40,
                        marginRight: 15,
                        color: "#676767",
                        "& .MuiSvgIcon-root": {
                          color: "#19d3c5",
                          "margin-right": "3px",
                        },
                      }}
                      IconComponent={ArrowIcon}
                      labelId="demo-simple-select-label"
                      id="SelCamp"
                      label="Elige tu Campus"
                      value={selectedCampus}
                      onChange={handleChangeCampus}
                      // Agrega estilos personalizados según sea necesario
                      //IconComponent={ArrowIcon}
                    >
                      {campus.map((campusGet) => (
                        <MenuItem key={campusGet.id} value={campusGet.sf_key}>
                          {campusGet.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Modal>
          </div>
          <Navbar
            onChangeCampus={handleCampusChangeInParent}
            setEventos={setEventos}
            __nivel={selectedGrade}
            __campus={selectedCampusName}
            changeModal={handleOpen}
          />
          <Banner imgName={imgName} campus={nombreCampus} />
          {isLoadingEventos ? (
            <Typography component="div" sx={{ textAlign: "center" }}>
              <span style={{ color: "#3393FD", fontSize: "20px" }}>
                Cargando eventos destacados para {nombreCampus}
              </span>
            </Typography>
          ) : carruselEventos.length !== 0 ? (
            <CarouselComponent eventos={carruselEventos} />
          ) : (
            ""
          )}

          {/* Proximos Eventos  */}
          <div>
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: 1,
                mb: 2.5,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography component="div" sx={{ mb: 2.5, textAlign: "center" }}>
                <span style={{ color: "#3393FD", fontSize: "20px" }}>
                  ¡Te damos la bienvenida!
                </span>
              </Typography>

              <Typography
                component="div"
                variant="h4"
                sx={{ mb: 2.5, textAlign: "center" }}
              >
                <b id="inicio">Eventos de {nombreCampus} </b>
              </Typography>

              <Typography component="div" sx={{ mb: 2.5, textAlign: "center" }}>
                <span style={{ color: "#9A9A9A", fontSize: "15px" }}>
                  Aquí encontrarás eventos exclusivos relacionados con tu camino
                  a formar parte del Tec de Monterrey. <br></br>Estamos para
                  guiarte en esta aventura hacia una educación excepcional. ¡Tu
                  futuro comienza ahora!
                </span>
              </Typography>
            </Grid>
            <div style={{ paddingLeft: "9%", paddingRight: "9%" }}>
              <Grid
                container
                sx={{
                  ml: "0px",
                  mr: "0px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                {isLoadingEventos ? (
                  <Typography component="div" sx={{ textAlign: "center" }}>
                    <span style={{ color: "#3393FD", fontSize: "20px" }}>
                      Cargando eventos para {nombreCampus}
                    </span>
                  </Typography>
                ) : (
                  (() => {
                    try {
                      if (eventos !== "error") {
                        if (
                          eventos &&
                          eventos[0] &&
                          eventos[0]["event"] &&
                          eventos[0]["event"].id_evento !== ""
                        ) {
                          return eventos.map((evento, key) =>
                            evento.event && evento.event.title ? (
                              <Grid
                                item
                                key={key}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{ p: 2 }}
                              >
                                <EventoDestacado
                                  areaEvent={evento.event.area}
                                  key={key}
                                  nombreEvento={evento.event.title}
                                  lugarEvento={evento.event.room_number}
                                  urlEvento={evento.event.urlname}
                                  urlImg={evento.event.imagen}
                                  tipoEvento={evento.event.tipo_evento}
                                />
                              </Grid>
                            ) : null
                          );
                        } else {
                          return (
                            <Typography
                              component="div"
                              sx={{ textAlign: "center" }}
                            >
                              <span
                                style={{ color: "#3393FD", fontSize: "20px" }}
                              >
                                Cargando eventos destacados para {nombreCampus}
                              </span>
                            </Typography>
                          );
                        }
                      } else {
                        return (
                          <>
                            <Grid
                              item
                              key={1}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              sx={{ p: 2 }}
                            >
                              <EventoDestacado
                                areaEvent={"1"}
                                key={"1"}
                                nombreEvento={"Contact Hub"}
                                lugarEvento={"off"}
                                urlEvento={
                                  "https://admision.tec.mx/contact-hub/home"
                                }
                                urlImg={"contacthub.webp"}
                                tipoEvento={" "}
                              />
                            </Grid>
                            {/* 
                            <Grid
                              item
                              key={2}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              sx={{ p: 2 }}
                            >
                              <EventoDestacado
                                areaEvent={"1"}
                                key={"2"}
                                nombreEvento={"Campus Tour"}
                                lugarEvento={"off"}
                                urlEvento={
                                  "https://admision.tec.mx/contact-hub/home"
                                }
                                urlImg={"campusTour.webp"}
                                tipoEvento={" "}
                              />
                            </Grid> */}
                          </>
                        );
                      }
                    } catch (error) {
                      console.error(error);
                      return (
                        <div className="spinner-container">
                          <div className="spinner"></div>
                        </div>
                      );
                    }
                  })()
                )}
              </Grid>
              {eventos.length > 0 && eventos.length >= 12 ? (
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{
                      margin: "20px",
                      padding: "20px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      borderRadius: 3,
                    }}
                    href={
                      "/" +
                      urlRoot +
                      "/" +
                      localStorage.getItem("nivelStorage") +
                      "/" +
                      localStorage.getItem("campusAlias")
                    }
                    variant="contained"
                  >
                    Ver más
                  </Button>
                </Grid>
              ) : null}
            </div>
          </div>
          {/*   Final Proximos Eventos  */}

          <div style={{ marginTop: "5%", height: "1px" }}>
            <Footer nivel={selectedGrade}></Footer>
          </div>
        </div>
      )}
    </div>
  );
};
export default Inicio; // Mueve la declaración de exportación fuera de la definición del componente
