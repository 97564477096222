import React, { useEffect, useState } from "react";
import { Campus } from "../../components/campus";
import { Navbar } from "../../components/Navbar";
import { apiUrlGestor } from "../../global";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Chip, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Pagination from "@mui/material/Pagination";
import Banner from "../../components/Banner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FiltrosComponent from "./filtrosComponent";
import { useLocation } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function callData(nivel, campusId, setEventos, setLoading) {
  setLoading(true); // Mostrar pantalla de carga al inicio
  setEventos([]);

  axios
    .post(+"campuses/search", {
      grade: nivel,
      name: campusId,
    })
    .then(function (response) {
      const campusData = response.data.data;
      axios
        .get(apiUrlGestor + "obtenerEventos.php?c=" + campusData[0]["sf_key"])
        .then(function (response) {
          const eventoData = response.data.events;
          setEventos(eventoData); // Actualizar el estado con los datos recibidos
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoading(false); // Ocultar pantalla de carga al finalizar
        });
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false); // Ocultar pantalla de carga si hay un error
    });
}

function CampusView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const busqueda = queryParams.get("busqueda");
  const nivelUrl = queryParams.get("nivel");
  const campusUrl = queryParams.get("campus");
  const fechaUrl = queryParams.get("fecha");
  const eventsPerPage = 10; // Cambia esto según tu preferencia
  const [currentPage, setCurrentPage] = useState(1);
  const [campus, setCampus] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [eventos, setEventos] = useState([]);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = eventos.slice(indexOfFirstEvent, indexOfLastEvent);
  const [imgName, setImgName] = useState("");
  const [chipsData, setChipsData] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [loading, setLoading] = useState(false);

  const buscar = (busqueda, setEventos, setLoading) => {
    setLoading(true); // Mostrar pantalla de carga al iniciar la búsqueda
    setEventos(""); // Limpiar eventos antes de realizar la búsqueda

    fetch(apiUrlGestor + "/obtenerEventosBusqueda.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(busqueda),
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(function (data) {
        const campusData = data.events;
        setEventos(campusData); // Actualizar eventos con los datos recibidos
      })
      .catch(function (error) {
        console.error("Error:", error);
      })
      .finally(function () {
        setLoading(false); // Ocultar pantalla de carga al finalizar la búsqueda
      });
  };

  const handleCampusChangeInParent = (selectedCampus, nivel) => {
    callData(nivel, selectedCampus, setEventos, setLoading);
  };
  ///Funcion que se detona cuando se da click en la x de los chips
  const handleDelete = (titulo, tipo) => {
    let data = {
      titulo: titulo,
      tipo: tipo,
    };
    removeChipByType(tipo);
    ///Variable que se envia a los filtros para poder quitar el check y/o los datos del chip eliminado
    setButtonClicked(data);
  };
  ///Funcion para quitar chips
  const removeChipByType = (tipo, removeChipByType = null) => {
    let updatedChipsData;
    if (Array.isArray(tipo) && tipo.length === 2) {
      if (removeChipByType != null) {
        updatedChipsData = removeChipByType.filter(
          (chip) => chip.tipo !== tipo[0] && chip.tipo !== tipo[1]
        );
      } else {
        updatedChipsData = chipsData.filter(
          (chip) => chip.tipo !== tipo[0] && chip.tipo !== tipo[1]
        );
      }
    } else {
      if (removeChipByType != null) {
        updatedChipsData = removeChipByType.filter(
          (chip) => chip.tipo !== tipo
        );
      } else {
        updatedChipsData = chipsData.filter((chip) => chip.tipo !== tipo);
        if (tipo === "nivel") {
          updatedChipsData = updatedChipsData.filter(
            (chip) => chip.tipo !== "campus" && chip.tipo !== "categoria"
          );
        }
      }
    }
    setChipsData(updatedChipsData);
    buscar(updatedChipsData, setEventos, setLoading);
  };
  ///Funcion para agregar chips
  const addChip = (
    titulo,
    tipo,
    flag = null,
    __newChip = null,
    fecha = null
  ) => {
    if (__newChip != null) {
      if (fecha != null) {
        let __chipsData = [];
        const newChipValue = {
          titulo: titulo,
          tipo: tipo,
          fecha: fecha !== null ? fecha : undefined,
        };
        const updatedNewChip = [...__newChip, newChipValue];
        __chipsData = [...chipsData, ...updatedNewChip];
        setChipsData(__chipsData);
        buscar(__chipsData, setEventos, setLoading);
      } else {
        let __chipsData = [];
        __chipsData = [...chipsData, ...__newChip];
        setChipsData(__chipsData);
        buscar(__chipsData, setEventos, setLoading);
      }
    } else {
      const existingChipIndex = chipsData.findIndex(
        (chip) => chip.tipo === tipo
      );
      if (existingChipIndex !== -1) {
        const updatedChipsData = chipsData.map((chip, index) => {
          if (index === existingChipIndex) {
            return {
              ...chip,
              titulo: titulo,
              fecha: fecha !== null ? fecha : undefined,
            };
          }
          return chip;
        });
        if (tipo === "nivel") {
          removeChipByType(["campus", "categoria"], updatedChipsData);
        } else {
          setChipsData(updatedChipsData);
          buscar(updatedChipsData, setEventos, setLoading);
        }
      } else {
        const newChip = {
          titulo: titulo,
          tipo: tipo,
          fecha: fecha !== null ? fecha : undefined, // Agregar fecha solo si no es null
        };
        const updatedChipsData = [...chipsData, newChip];
        setChipsData(updatedChipsData);
        if (flag == null) {
          buscar(updatedChipsData, setEventos, setLoading);
        }
      }
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchImgName = () => {
    fetch(apiUrlGestor + "obtenerImg.php")
      .then((response) => response.json())
      .then((data) => {
        setImgName(data["data"][0]["imagen"]);
      })
      .catch((error) => {
        console.log("Error al obtener los banners", error);
      });
  };

  useEffect(() => {
    const fetchEventData = async () => {
      moment.locale("es");
      if (busqueda != null) {
        addChip(busqueda, "busqueda");
      }

      if (campusUrl != null) {
        setCampus(campusUrl);
      } else {
        setCampus(localStorage.getItem("campusStorageName"));
      }
      if (nivelUrl != null) {
        if (campusUrl != null) {
          setCampus(campusUrl);
        } else {
          setCampus([]);
        }
        setSelectedGrade(nivelUrl);
      } else {
        setSelectedGrade(localStorage.getItem("nivelStorage"));
      }

      fetchImgName();
    };

    fetchEventData();
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [busqueda]);

  return (
    <div>
      <Navbar
        onChangeCampus={handleCampusChangeInParent}
        setEventos={setEventos}
        __nivel={selectedGrade}
        __campus={""}
      />
      <Banner imgName={imgName} campus={"off"} />
      <Box sx={{ flexGrow: 1, mt: 2.5 }}>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          container
          spacing={2}
        >
          {" "}
          <Grid item xs={3}>
            <Grid item xs={12}>
              <Typography
                component="div"
                variant="h4"
                sx={{
                  mb: 2.5,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <FormatListBulletedIcon sx={{ fontSize: "2.4rem" }} />
                Filtros
              </Typography>
              <FiltrosComponent
                buttonClicked={buttonClicked}
                removeChipByType={removeChipByType}
                onChangeCampus={handleCampusChangeInParent}
                addChip={addChip}
                __nivel={selectedGrade}
                __campus={campus}
                __date={fechaUrl}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <Typography component="div" variant="h4" sx={{ mb: 2.5 }}>
                <b>Elige tu evento</b>
              </Typography>
              {chipsData.map(
                (chipData, key) =>
                  !chipData.extraParam && (
                    <Chip
                      key={key}
                      label={chipData.titulo}
                      variant="outlined"
                      onDelete={() =>
                        handleDelete(chipData.titulo, chipData.tipo)
                      }
                    />
                  )
              )}
            </Grid>

            {loading ? ( // Verificar si se está cargando
              <div className="overlay">
                <div className="spinner"></div>
              </div>
            ) : eventos.length === 0 ? ( // Mostrar mensaje si no hay eventos
              <Box>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ mb: 2.5, mt: 3, textAlign: "center" }}
                >
                  <b> No hay eventos disponibles.</b>
                </Typography>
              </Box>
            ) : (
              <div>
                {currentEvents.map((evento, key) => (
                  <Campus
                    key={key}
                    areaEvent={evento.event.area}
                    nombreEvento={evento.event.title}
                    subtituloEvento={evento.event.description_text}
                    urlImg={evento.event.imagen}
                    fechaEvento={capitalizeFirstLetter(
                      moment(evento.event.fecha_evento).format(
                        "dddd, D [de] MMMM"
                      )
                    )}
                    ubicacionEvento={evento.event.ubicacion}
                    idEvento={evento.event.urlname}
                  />
                ))}
                <Pagination
                  count={Math.ceil(eventos.length / eventsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "5%", height: "1px" }}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default CampusView;
