import { Route, Routes, useMatch } from "react-router-dom";
import Inicio from "../Screens/Inicio/Inicio";
import Evento from "../Screens/Evento/Evento";
import Campus from "../Screens/Campus/Campus";
import Busqueda from "../Screens/Busqueda/Busqueda";
import Error404 from "../Screens/Inicio/404";

export default function AppRouter() {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Inicio />} />
        <Route path="/*" element={<Error404Route />} />
        <Route exact path="/evento/:eventId" element={<Evento />} />
        <Route exact path="/evento/:eventId/gracias" element={<Evento />} />
        <Route exact path="/:nivelParam/:campusId" element={<Campus />} />
        <Route exact path="/busqueda" element={<Busqueda />} />
      </Routes>
    </>
  );
}

function Error404Route() {
  const match = useMatch("/*");
  return <Error404 path={match?.params["*"]} />;
}
